import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Logo from '../../images/Logo.png';

import HomePage from '../homepage';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import { Box } from '@material-ui/core';
import BubbleChartMain from '../qualitative compatibility/bubblechart/BubbleChartMain';
import {Redirect} from 'react-router-dom';
import Quantitative from '../Quantitative data/Quantitative';
import QualitativeBubble from '../qualitative compatibility/bubblechart/QualitativeBubble';
import MultiselectDropdown from '../qualitative compatibility/bubblechart/MultiselectDropdown';
import DataVolume from '../Information/Data Volume/DataVolume';
import SubmitNewData from '../Submit new data/SubmitNewData';
import SubmitFeedback from '../Submit feedback/SubmitFeedback';
import ProgressReport from '../Progress Report/ProgressReport';
import SolubilityParam from '../Solubility/SolubilityParam';
import SpherePlot from '../qualitative compatibility/bubblechart/SpherePlot';
import HansenSolubility from '../Solubility/HansenSolubility';
import QualitativeBubbleCopy from '../qualitative compatibility/bubblechart/QualitativeBubbleCopy';
import BubbleChartMainCopy from '../qualitative compatibility/bubblechart/BubbleChartMainCopy';
import Home from '../SearchEngine/Home';
import ProgressReport1 from '../Progress Report/ProgressReport1';
import pdf1 from '../../images/SR.20.01036.pdf'
import pdf2 from '../../images/SR.20.01321.pdf'
import pdf3 from '../../images/SRN-05947.pdf'

const drawerWidth = 280;
const menuId = 'primary-search-account-menu';
const mobileMenuId = 'primary-search-account-menu-mobile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function ShellHeaderDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openQualitativeCompatibility, setOpenQualitativeCompatibility] = React.useState(false);
  const [openDEP, setOpenDEP] = React.useState(false)
  const [openWelcome, setOpenWelcome] = React.useState(true)
  const [openCRAGuide, setOpenCRAGuide] = React.useState(false)
  const [openGeneral, setOpenGeneral] = React.useState(false)
  const [openCompatibilityCCS, setOpenCompatibilityCCS] = React.useState(false)
  const [openInformation, setOpenInformation] = React.useState(false)
  const [openDataORings, setOpenDataORIngs] = React.useState(false)
  const [openDataSeals, setOpenDataSeals] = React.useState(false)
  const [openBoundaryParameter, setOpenBoundaryParameter] = React.useState(false)
  const [openMaterialRecommendation, setOpenMaterialRecommendation] = React.useState(false)
  const [openQuantitativeData, setOpenQuantitativeData] = React.useState(false)
  const [openDataConsolv, setOpenDataConsolv] = React.useState(false)
  const [openHomePage, setOpenHomePage] = React.useState(true)
  const [openDataImpurityOnly, setOpenDataImpurityOnly] = React.useState(false)
  const [openPredictive, setOpenPredictive] = React.useState(false)
  const [openPredictiveCracking, setOpenPredictiveCracking] = React.useState(false)
  const [openSelectPolymer, setOpenSelectPolymer] = React.useState(false)
  const [openThirdProgress, setOpenThirdProgress] = React.useState(false)
  const [openFirstProgress, setOpenFirstProgress] = React.useState(false)
  const [openSecondProgress, setOpenSecondProgress] = React.useState(false)
  const [openFourthProgress, setOpenFourthProgress] = React.useState(false)
  const [openSolubility, setOpenSolubility] = useState(false)
  const [openHilderand, setOpenHilderand] = useState(false)
  const [openHansen, setOpenHansen] = useState(false)
  const [openSubmitFeedback, setOpenSubmitFeedback] = React.useState(false)
  const [openSubmitData, setOpenSubmitData] = React.useState(false)
  const [openDataImpurityPlus, setOpenDataImpurityPlus] = React.useState(false)
  const [openRepository, setOpenRepository] = React.useState(false);
  const [openVolChangePressure, setOpenVolChangePressure] = React.useState(false)
  const [openDataMaterialsCO2, setOpenDataMaterialsCO2] = React.useState(false)
  const [openVolChangeTemp, setOpenVolChangeTemp] = React.useState(false)
  const [openBubbleChart, setOpenBubbleChart] = React.useState(false)
  const [openContourPlotTemp, setOpenContourPlotTemp] = React.useState(false)
  const [openCountourPlot, setOpenCountourPlot] = React.useState(false)
  const [openLiterature, setOpenLiterature] = React.useState(false)

  const [openBubbleChartTemp, setBubbleChartTemp] = React.useState(false)
  const [pageTitle, setPageTitle] = React.useState('');
  const [openVolChangeTime, setOpenVolChangeTime] = React.useState(false)

  const [openSearchReports, setOpenSearchReports] = React.useState(false)
  const [openDataVolume, setOpenDataVolume] = React.useState(false)
  const [openUserVolume, setOpenUserVolume] = React.useState(false)
  const [openHighTempData, setOpenHighTempData] = React.useState(false)
  const [openMechanicalCompatibility, setOpenMechanicalCompatibility] = React.useState(false)
  const [openSelectExposure, setOpenSelectExposure] = React.useState(false)
  const [openTestingLabHome, setOpenTestingLabHome] = React.useState(false)



  async function unHighlightSublevelMenu() {
    setOpenUserVolume(false)
    setOpenDataImpurityOnly(false);
    setOpenQuantitativeData(false);
    setOpenDataConsolv(false)
    setOpenDataORIngs(false);
    setOpenDataSeals(false);
    setOpenBoundaryParameter(false);
    setOpenMaterialRecommendation(false);
    setOpenPredictiveCracking(false);
    setOpenHomePage(false);
    setOpenSelectPolymer(false);
    setOpenSecondProgress(false);
    setOpenFourthProgress(false);
    setOpenThirdProgress(false);
    setOpenFirstProgress(false);
    setOpenSubmitData(false);
    setOpenSubmitFeedback(false);
    setOpenDataImpurityPlus(false);
    setOpenVolChangePressure(false);
    setOpenDataMaterialsCO2(false);
    setOpenHilderand(false);
    setOpenHansen(false);
    setOpenVolChangeTemp(false);
    setOpenBubbleChart(false);
    setBubbleChartTemp(false);
    setOpenContourPlotTemp(false);
    setOpenCountourPlot(false);
    setOpenLiterature(false);
    setOpenVolChangeTime(false)
    setOpenSearchReports(false)
    setOpenDataVolume(false)
    setOpenHighTempData(false)
    setOpenMechanicalCompatibility(false)
    setOpenSelectExposure(false)
    setOpenTestingLabHome(false)
    setOpenWelcome(false)
  }

  const handleThirdProgressReport = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('PROGRESS REPORT III')
    setOpenThirdProgress(true);
  }
  const handleFirstProgressReport = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('PROGRESS REPORT I')
    setOpenFirstProgress(true);
  }
  const handleSecondProgressReport = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('PROGRESS REPORT II')
    setOpenSecondProgress(true);
  }
  const handleFourthProgressReport = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('PROGRESS REPORT IV')
    setOpenFourthProgress(true);
  }
  const handleSubmitFeedback = async () => {
    await unHighlightSublevelMenu();
    clearMainMenuSelection();
    setPageTitle('SUBMIT FEEDBACK')
    setOpenSubmitFeedback(true);
  }
  // const handleSolubility = async () => {
  //   await unHighlightSublevelMenu();
  //   clearMainMenuSelection();
  //   setPageTitle('Solubility Parameters')
  //   setOpenSolubility(true);
  // }
  function handleSolubility() {
    var currentRepor = openSolubility;
    clearMainMenuSelection();
    setOpenSolubility(!currentRepor);

  }
  const handleSubmitData = async () => {
    await unHighlightSublevelMenu();
    clearMainMenuSelection();
    setPageTitle('SUBMIT NEW DATA')
    setOpenSubmitData(true);
  }
  const handleClickTestCategories = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Chemical resistance of polymer')
    setOpenSelectExposure(true)
  }
  const handleSearchReports = async () => {
    await unHighlightSublevelMenu();
    clearMainMenuSelection();
    setPageTitle('SEARCH REPORTS')
    setOpenSearchReports(true);
  }

  const handleDataVolume = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('DATA VOLUME')
    setOpenDataVolume(true);
  }
  const handleUserVolume = async () => {
    await unHighlightSublevelMenu();
    clearMainMenuSelection();
    setPageTitle('USER MANUAL')
    setOpenUserVolume(true);
  }

  const handleMechanicalCompatibility = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Mechanical resistance of polymer');
    setOpenMechanicalCompatibility(true);
  }


  const handleVolChangeTime = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Volume change of polymer with exposure time')
    setOpenVolChangeTime(true);
  }

  const handleAlloyCompositions = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('BUBBLE CHART')
    setOpenBubbleChart(true);
  }
  const handleTestProtocol = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('CONTOUR PLOT')
    setOpenCountourPlot(true);
  }
  const handleIsoNace = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('CONTOUR PLOT HIGH TEMP. DATA')
    setOpenContourPlotTemp(true);
  }
  const handlePhCalculation = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('HIGH TEMP. DATA OTHER EXPOSURE MEDIUM')
    setOpenHighTempData(true)
  }

  const handleCraCP = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('BUBBLE CHART HIGH TEMP. DATA')
    setBubbleChartTemp(true)
  }
  const handleVolChangeTemp = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Volume change of polymer with exposure temperature')
    setOpenVolChangeTemp(true);
  }
  const handleVolChangePressure = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Volume change of polymer with exposure pressure')
    setOpenVolChangePressure(true);
  }

  const handleClicksFieldFailuers = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUALITATIVE DATA IN CO2')
    setOpenDataMaterialsCO2(true);
  }
  const handleClicksHildebrand = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('HILDEBRAND SOLUBILITY PARAMETERS')
    setOpenHilderand(true);
  }
  const handleClicksHansen = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('HANSEN SOLUBILITY PARAMETERS')
    setOpenHansen(true);
  }



  const handleDataImpurityPlus = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUANTITATIVE DATA CO2 + IMPURITY')
    setOpenDataImpurityPlus(true);
  }
  const handleDataORings = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUANTITATIVE DATA O-RINGS IN CO2')
    setOpenDataORIngs(true);
  }
  const handleDataSeals = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUANTITATIVE DATA SEALS IN CO2')
    setOpenDataSeals(true);
  }
  const handleQuantitativeData = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUANTITATIVE DATA')
    setOpenQuantitativeData(true);
  }
  const handleDataConcolv = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUANTITATIVE DATA FRP CANSOLV DC-103')
    setOpenDataConsolv(true);
  }
  const handleClickCracking = async () => {
    await unHighlightSublevelMenu();
    setOpenPredictiveCracking(true);
  }

  const handleClickBoundaryParameter = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('INTEGRITY OPERATING WINDOW')
    setOpenBoundaryParameter(true);
  }
  const handleClickMaterialRecommendation = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('MATERIAL RECOMMENDATION')
    setOpenMaterialRecommendation(true);
  }

  const handleHomeClick = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('')
    setOpenHomePage(true);
  }


  function handleClickRepository() {
    var currentRepor = openRepository;
    clearMainMenuSelection();
    setOpenRepository(!currentRepor);

  }
  function handleClickCompatibiltyCCS() {
    var currentRepor = openCompatibilityCCS;
    clearMainMenuSelection();
    setOpenCompatibilityCCS(!currentRepor);

  }
  function handleClickInformation() {
    var currentRepor = openInformation;
    clearMainMenuSelection();
    setOpenInformation(!currentRepor);

  }
  const handleClickDataCentralization = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('Chemical resistance of polymer')
    setOpenSelectPolymer(true);
  }

  const handleClickTestingLabHome = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('')
    setOpenTestingLabHome(true);
  }
  const handleLITERATURE = async () => {
    clearMainMenuSelection();
    await unHighlightSublevelMenu();
    setPageTitle('LITERATURE SEARCH')
    setOpenLiterature(true);
  }

  const handleDataImpurityOnly = async () => {
    await unHighlightSublevelMenu();
    setPageTitle('QUALITATIVE DATA IN CO2 WITHOUT IMPURITY')
    setOpenDataImpurityOnly(true);
  }


  function clearMainMenuSelection() {
    setOpenGeneral(false);
    // setOpenMaterial(false);
    setOpenQualitativeCompatibility(false);
    setOpenPredictive(false);
    setOpenCompatibilityCCS(false);
    setOpenInformation(false)
    setOpenRepository(false)
    setOpenWelcome(false)
  }

  function handleClickQualitativeCompatibility() {
    //  var currentGeneral=openGeneral;
    var currentGeneral = openQualitativeCompatibility;
    clearMainMenuSelection();
    setOpenQualitativeCompatibility(!currentGeneral);

  }
  function handleWelcome() {
    //  var currentGeneral=openGeneral;
    // var currentGeneral = openWelcome;
    // clearMainMenuSelection();
    unHighlightSublevelMenu();
    setPageTitle('')
    setOpenWelcome(true);

  }


  function handleOpenClickMaterial() {

    clearMainMenuSelection();
    setOpenQualitativeCompatibility(true);
    // setOpenMaterial(true);

  }
  function handleOpenClickPredictive() {
    clearMainMenuSelection();
    setOpenPredictive(true);

  }
  function handleOpenClickRepository() {

    // var currentRepor=openRepository;
    clearMainMenuSelection();
    setOpenRepository(true);


  }
  function handleOpenClickDEP() {
    setOpenDEP(true);
    setOpenCRAGuide(false);

  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const selectedMenuClass = classes.menuItem + ' selectedMenuItem';
  const homePageMainlinkFuncs = [handleOpenClickMaterial, handleOpenClickMaterial, handleOpenClickPredictive, handleOpenClickRepository, handleOpenClickRepository];
  const homePageSublinkFuncs = [handleOpenClickDEP, handleOpenClickDEP, null, null, null];

  const homePagelinkFuncs = [handleClickMaterialRecommendation, handleClickBoundaryParameter, handleClickCracking, handleClickTestingLabHome, handleLITERATURE];

  // const testingLabMainLinkFuncs=[handleOpenClickMaterial, handleOpenClickMaterial, handleOpenClickPredictive,handleOpenClickRepository, handleOpenClickRepository];
  // const testingLabSublinkFuncs=[handleOpenClickDEP, handleOpenClickDEP, null, null,null];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogout, setIsLogout] = useState(false)

  const testingLablinkFuncs = [handleClickDataCentralization, handleClickTestCategories];
  const settings = ['Logout'];
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //  setIsLogout(true)
  //   setAnchorElUser(null);
  //   // localStorage.clear();
  //   // window.location.href = '/'
  // };
  const handleLogout = () => {
    setAnchorElUser(null);
    localStorage.clear();
    window.location.href = '/'
  };

  let redirectUri = window.location.origin
  console.log("redirecturll",window.location.host)
  return (
    <div className={classes.grow}>
      <CssBaseline />
      <div id='headerAppbar'>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {/* <img style={{height:60}} src={pecten} alt="picten"></img> */}

            <Typography variant="h6" noWrap>

              <img className='MatLogoImg' src={Logo} alt="picten"></img>

            </Typography>
            <div className='MatLogoText'>Digital Polymer</div>
            <div className={classes.grow} />

            {/* <InfoIcon  style={{"marginRight":"20px"}} ></InfoIcon> */}
            <a href="#" style={{ 'color': '#595959', marginTop: '5px' }} target="_blank">

              <LanguageIcon style={{ "marginRight": "20px" }} >

              </LanguageIcon> </a>

            <HomeIcon 
            // onClick={handleHomeClick} 
            onClick={handleWelcome}
            style={{ "marginRight": "20px",cursor:"pointer" }} ></HomeIcon>


            <div className={classes.sectionDesktop}>
              <div className='username'></div>
              <IconButton
                edge="end"
                onClick={handleOpenUserMenu}
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleLogout}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                // onClick={handleMobileMenuOpen}
                color="inherit"
              >
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <Divider />

        <Box component="div" sx={{overFlow:"auto"}}>

        
        {/* <ListItem button onClick={handleWelcome} className={openWelcome ? selectedMenuClass : classes.menuItem}>
          <ListItemText primary="WELCOME" />
        </ListItem> */}
        <ListItem button onClick={handleClickQualitativeCompatibility} className={classes.menuItem, openQualitativeCompatibility && 'selectedMainMenu'}>
          <ListItemText primary="QUALITATIVE COMPATIBILITY DATA" />
          {openQualitativeCompatibility ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={openQualitativeCompatibility} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem button onClick={handleAlloyCompositions} className={openBubbleChart ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="BUBBLE CHART" />
            </ListItem>
            <ListItem button onClick={handleTestProtocol} className={openCountourPlot ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="CONTOUR PLOT" />
            </ListItem>
            {/* <ListItem button onClick={handleClickTestCategories} className={openSelectExposure?selectedMenuClass:classes.menuItem}>
            <ListItemText inset primary="TEST CATEGORIES" />
          </ListItem> */}
            <ListItem button onClick={handleCraCP} className={openBubbleChartTemp ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="BUBBLE CHART HIGH TEMP. DATA" />
            </ListItem>
            <ListItem button onClick={handleIsoNace} className={openContourPlotTemp ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="CONTOUR PLOT HIGH TEMP. DATA" />
            </ListItem>
            <ListItem button onClick={handlePhCalculation} className={openHighTempData ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="HIGH TEMP. DATA OTHER EXPOSURE MEDIUM" />
            </ListItem>
            {/* <ListItem button onClick={handleClickCalculation} className={classes.menuItem, openCalculation && 'selectedMainMenu'}>
              <ListItemText inset primary="CALCULATION TOOLS" />
              {openCalculation ? <IconExpandLess /> : <IconExpandMore />}

            </ListItem> */}
            {/* <Collapse in={openCalculation} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" style={{ 'margin-left': '10px' }} disablePadding>
                <ListItem button onClick={handlePhCalculation} className={openPhCalculation ? selectedMenuClass : classes.menuItem}>
                  <ListItemText inset primary="pH CALCULATION" />
                </ListItem>

              </List>
            </Collapse> */}

          </List>
        </Collapse>

        <Divider />
        <ListItem button onClick={handleClickRepository} className={classes.menuItem, openRepository && 'selectedMainMenu'}>
          <ListItemText primary="QUANTITATIVE DATA" />
          {openRepository ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={openRepository} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem button onClick={handleClickDataCentralization} className={openSelectPolymer ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="CHEMICAL COMPATIBILITY (BASED ON POLYMER) " />
            </ListItem>
          
            <ListItem button onClick={handleClickTestCategories} className={openSelectExposure ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="CHEMICAL COMPATIBILITY (BASED ON EXPOSURE MEDIUM)" />
            </ListItem>
            <ListItem button onClick={handleMechanicalCompatibility} className={openMechanicalCompatibility ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="MECHANICAL COMPATIBILITY" />
            </ListItem>
            <ListItem button onClick={handleVolChangeTime} className={openVolChangeTime ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="VOL. CHANGE VS. TIME" />
            </ListItem>
            <ListItem button onClick={handleVolChangeTemp} className={openVolChangeTemp ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="VOL. CHANGE VS. TEMP" />
            </ListItem>
            <ListItem button onClick={handleVolChangePressure} className={openVolChangePressure ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="VOL. CHANGE VS. PRESSURE" />
            </ListItem>
            <ListItem button onClick={handleDataConcolv} className={openDataConsolv ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA FRP CANSOLV DC-103" />
            </ListItem>


          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleClickCompatibiltyCCS} className={classes.menuItem, openCompatibilityCCS && 'selectedMainMenu'}>
          <ListItemText primary="COMPATIBILITY DATA FOR CCS" />
          {openCompatibilityCCS ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={openCompatibilityCCS} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem button onClick={handleClicksFieldFailuers} className={openDataMaterialsCO2 ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUALITATIVE DATA IN CO2" />
            </ListItem>
            <ListItem button onClick={handleDataImpurityOnly} className={openDataImpurityOnly ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUALITATIVE DATA IN CO2 WITHOUT IMPURITY" />
            </ListItem>
            <ListItem button onClick={handleQuantitativeData} className={openQuantitativeData ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA IN CO2" />
            </ListItem>
            <ListItem button onClick={handleDataImpurityPlus} className={openDataImpurityPlus ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA IN CO2 + IMPURITY" />
            </ListItem>
            <ListItem button onClick={handleDataORings} className={openDataORings ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA O-RINGS IN CO2" />
            </ListItem>
            <ListItem button onClick={handleDataSeals} className={openDataSeals ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA SEALS IN CO2" />
            </ListItem>
         
            {/* <ListItem button onClick={handleDataConcolv} className={openDataConsolv ? selectedMenuClass : classes.menuItem}>
              <ListItemText inset primary="QUANTITATIVE DATA FRP CANSOLV DC-103" />
            </ListItem> */}
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleSolubility} className={classes.menuItem, openSolubility && 'selectedMainMenu'}>
          <ListItemText primary="SOLUBILITY PARAMETERS (UNDER REVIEW)" />
          {openInformation ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={openSolubility} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
          <ListItem style={{marginLeft:"24px"}} button onClick={handleClicksHildebrand} className={openHilderand ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="HILDEBRAND" />
          </ListItem>
          <ListItem style={{marginLeft:"24px"}} button onClick={handleClicksHansen} className={openHansen ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="HANSEN" />
          </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleClickInformation} className={classes.menuItem, openInformation && 'selectedMainMenu'}>
          <ListItemText primary="INFORMATION" />
          {openInformation ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={openInformation} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
          {/* <ListItem style={{marginLeft:"24px"}} button onClick={handleDataVolume} className={openDataVolume ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="DATA VOLUME" />
          </ListItem> */}
          {/* <ListItem style={{marginLeft:"24px"}} button onClick={handleSecondProgressReport} className={openSecondProgress ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="PROGRESS REPORT II" />
          </ListItem> */}
         
          <ListItem style={{marginLeft:"24px"}} button onClick={handleFirstProgressReport} className={openFirstProgress ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="PROGRESS REPORT I" />
          </ListItem>
          <ListItem style={{marginLeft:"24px"}} button onClick={handleSecondProgressReport} className={openSecondProgress ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="PROGRESS REPORT II" />
          </ListItem>
          <ListItem style={{marginLeft:"24px"}} button onClick={handleThirdProgressReport} className={openThirdProgress ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="PROGRESS REPORT III" />
          </ListItem>
          <ListItem style={{marginLeft:"24px"}} button onClick={handleFourthProgressReport} className={openFourthProgress ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="PROGRESS REPORT IV" />
          </ListItem>
          </List>
        </Collapse>
        <Divider/>
      
        <Divider/>
        <List component="div" disablePadding>
          {/* <ListItem button onClick={handleSearchReports} className={openSearchReports ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="SEARCH REPORTS" />
          </ListItem>     */}
          {/* <ListItem button onClick={handleUserVolume} className={openUserVolume ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="USER MANUAL" />
          </ListItem> */}
          <ListItem button onClick={handleSubmitFeedback} className={openSubmitFeedback ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="SUBMIT FEEDBACK" />
          </ListItem>
          <ListItem button onClick={handleSubmitData} className={openSubmitData ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="SUBMIT NEW DATA" />
          </ListItem>
        
          {/* <ListItem button onClick={handleSolubility} className={openSolubility ? selectedMenuClass : classes.menuItem}>
            <ListItemText primary="SOLUBILITY PARAMTERS" />
          </ListItem> */}
        </List>
        <ListItem button onClick={handleLITERATURE} className={openLiterature?selectedMenuClass:classes.menuItem}>
            <ListItemText  primary="LITERATURE SEARCH (UNDER REVIEW)" />
          </ListItem>
        <List>

        </List>
        </Box>

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <Typography paragraph style={{ 'margin-left': '280px' }}>
          {pageTitle !== '' && <h3 style={{ 'font-size': '18px', 'font-weight': 'bold', 'color': 'darkgray' }}>{pageTitle}</h3>}
          {openWelcome &&

            <HomePage homePagelinkFuncs={homePagelinkFuncs} homePageSublinkFuncs={homePageSublinkFuncs}
              homePageMainlinkFuncs={homePageMainlinkFuncs} ></HomePage>}
              {/* Qualitative compatibility data pages */}
              {/* Bubble chart */}
              {openBubbleChart && <QualitativeBubbleCopy chartType="bubble" polymer="" />}
              {/* contour plot  */}
              {openCountourPlot && <QualitativeBubbleCopy chartType="qualitativeContour" type=""  polymer="" />}
                {/* Bubble chart High temp data  */}
              {/* {openBubbleChartTemp && <BubbleChartMain chartType="highBubble" />} */}
              {openBubbleChartTemp && <BubbleChartMainCopy chartType="highBubble" />}
              {/* Contour plot High temp data */}
              {openContourPlotTemp && <Quantitative chartType="qualitativeContourHighTemp" type=""  polymer="" />}

              {/* High temp data other exposure medium  */}
              {openHighTempData && <BubbleChartMain chartType="highDataExposure" />}

              {openSelectPolymer && <Quantitative chartType="selectPolymer"  polymer="" />}
              {openMechanicalCompatibility && <Quantitative chartType="mechnicalCompatibility" polymer="" />}
              {openSelectExposure && <Quantitative chartType="chemicalCompatibilitySelectExposure"  polymer="" />}
              {openVolChangeTime && <Quantitative chartType="volChangeTime" type="line" polymer="" />}
              {openVolChangeTemp && <Quantitative chartType="volChangeTemp" type="" polymer="" />}
              {openVolChangePressure && <Quantitative chartType="volChangePressure" type="pressure"  polymer="" />}
              {openDataMaterialsCO2 && <Quantitative chartType="dataMaterialsCO2" type=""  polymer="" />}
              {openDataImpurityOnly && <Quantitative chartType="dataImpurityOnly" type=""  polymer="" />}
              {openDataImpurityPlus && <Quantitative chartType="DataImpurityCO2" type=""  polymer="" />}
              {openDataORings && <Quantitative chartType="openDataORings" type=""  polymer="" />}
              {openDataSeals && <Quantitative chartType="quantitativesealsco2" type=""  polymer="" />}
              {openQuantitativeData && <Quantitative chartType="quantitativeData" type=""  polymer="" />}
              {openDataConsolv && <Quantitative chartType="dataConsolv" type=""  polymer="" />}
              {/* {openDataVolume && <DataVolume/>} */}
              {openSubmitData && <SubmitNewData/>}
              {openHilderand && <SolubilityParam/>}
              {openHansen && <HansenSolubility/>}
              {openSubmitFeedback && <SubmitFeedback/>}
              {openThirdProgress && <ProgressReport/>}
              {openFirstProgress && <ProgressReport1 pdf={pdf1}/>}
              {openSecondProgress && <ProgressReport1 pdf={pdf2}/>}
              {openFourthProgress && <ProgressReport1 pdf={pdf3}/>}
              {openLiterature && <Home/>}
          {/* {openCountourPlot && <img style={{'width':'1000px'}} src={test_protocols}></img>} */}
          {/* {openBubbleChart && <AlloyCompositions/>} */}

          {/* {openTestingLabHome && <TestingLabHome testingLablinkFuncs={testingLablinkFuncs}></TestingLabHome>} */}

        </Typography>

      </main>
    </div>
  );
}